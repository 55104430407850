/*-- about css start--*/

.indx_title_main_wrapper.abt{
    background:url('../../../public/images/racing/footer_bg.png') 50% 0 repeat-y;
    background-position:center 0;
    background-size:cover;
    background-repeat:no-repeat;
    float:left;
    width:100%;
    height:100%;
    position:relative;
    padding-top:50px;
    padding-bottom:50px;
}
.abt .title_img_overlay{
    position:absolute;
    top:0%;
    bottom:0%;
    left:0%;
    right:0%;
    background:rgba(0,0,0,0.8);
}
.abt .indx_title_left_wrapper{
    float:left;
    width:100%;
}
.abt .indx_title_left_wrapper h2{
    font-size:28px;
    color:#ffffff;
    text-transform:capitalize;
    font-weight:500;
}
.abt .indx_title_right_wrapper{
    float:left;
    width:100%;
    padding-top:6px;
}
.abt .indx_title_right_wrapper ul{
    float:right;
}
.abt .indx_title_right_wrapper li{
    float:left;
    color:#ffffff;
    text-transform:capitalize;
}
.abt .indx_title_right_wrapper li a{
    color:#ffffff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .indx_title_right_wrapper li a:hover{
    color:#f7e82d;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .indx_title_right_wrapper li:last-child{
    color:#f7e82d;
    margin-left:10px;
}
/*-- hs title wrapper End --*/




/*-- about inner css --*/
.iner_about_wrapper.abt{
    padding-top:100px;
    padding-bottom:120px;
    background:#fff;
    text-align:center;
}
.abt .about_slider_wrapper  .owl-carousel{
    z-index:auto;
}
.about_slider_wrapper  .owl-theme .owl-dots{
    position:absolute;
    left:0;
    right:0;
    margin:0px auto;
    bottom:-60px;
}
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot{
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot span{
    width: 8px;
    height: 8px;
    display: block;
    margin: 5px;
    background: #f7e82d;
    border-radius: 30px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot.active span, 
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot:hover span  {
    background:#002398;
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot.active, 
.abt .about_slider_wrapper .owl-theme .owl-dots .owl-dot:hover{
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.abt .about_slider_wrapper .owl-theme .owl-nav {
    display:none;
}
.abt .welcome_link{
    float:left;
    width:100%;
    padding-top:20px;
}
.abt .about_welcome_content{
    text-align: left;
    padding-bottom: 14px;
}
.abt .welcome_link li{
    float:none;
    font-size:16px;
    text-transform:capitalize;
    margin-bottom:10px;
}
.abt .welcome_link li a{
    color:#111;
}
.abt .welcome_link li a i{
    color:#002398;
    padding-right:15px;
}
.abt .welcome_link li a:hover{
    color:#002398;
}
.abt .about_welcome_content h1{
    font-size:26px;
    color:#111;
    font-weight:500;
    text-transform:capitalize;
}
.abt .about_welcome_content p{
    padding-top:20px;
}
          
 .indx_title_main_wrapper.float_left.abt{margin-top: 40px;}

 .iner_about_wrapper.abt {
    padding-top: 40px;
    padding-bottom: 80px;
    background: #fff;
    text-align: center;
}

/*-- about css start--*/